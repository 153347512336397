import React, { Component } from "react";
import { Button } from "antd";
import { BackendUrl } from "../../../../actions/mainActions";

// Styles
import './totalApplication.scss'

// Component
import TotalsSumBlock from "../TotalsSumBlock/totalsBlock";
import NewProjectCard from "./newProjectCard";

class TotalApplicationBlock extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      paymentType: 'bank',
      showFormRequisites: false,

      uniqueBasket: this.props.uniqueBasket,
      totalSum: {
        sum: 0,
        projects: []
      },
    }
  }

  plusKind = (kind, count) => {
    const uniqueItems = Array.from(new Set(this.state.uniqueBasket))
    const idx = this.state.uniqueBasket.findIndex(item => item.data === kind)
    
    uniqueItems[idx].count = count + 1;
    this.setState({
      uniqueBasket: uniqueItems,
    })
  }
  minusKind = (kind, count) => {
    const uniqueItems = Array.from(new Set(this.state.uniqueBasket))
    const idx = this.state.uniqueBasket.findIndex(item => item.data === kind)
    
    uniqueItems[idx].count = count - 1;
    this.setState({
      uniqueBasket: uniqueItems,
    })
  }

  selectCompany = (kind, company) => {
    const uniqueItems = Array.from(new Set(this.state.uniqueBasket))
    const idx = this.state.uniqueBasket.findIndex(item => item.data === kind)
    
    uniqueItems[idx].company = company;
    this.setState({
      uniqueBasket: uniqueItems,
    })
  }

  render() {
    const formatToCurrnecy = number => new Intl.NumberFormat('ru-RU').format(number) + ' тг'

    return (
      <div className="sending-application">
        <h3 className="step-title">Ваш выбор</h3>
        <div className="basket">

          <div className="basket-item">
            {
              this.props.categoryData
                ? (
                    <div className="plotSearch-item">
                      <div className="left">
                        <div className="plotSearch-preview" style={{backgroundImage: `url(${require(`../../../../assets/images/tmp/plot-search/1.jpg`)})`}}></div>
                        <div className="plotSearch-content">
                          <h4 className="plotSearch-subtitle">{this.props.categoryData.region}</h4>
                          <h3 className="plotSearch-title">{this.props.categoryData.location}</h3>
                          <ul className="plotSearch-list">
                            {
                              this.props.categoryData.terrainList.map((terrain, key) => (
                                <li
                                  key={key}
                                  className="plotSearch-list-li"
                                >
                                  {terrain}
                                </li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                      <div className="plotSearch-footer">
                        <p className="plotSearch-price">{formatToCurrnecy(this.props.categoryData.price)}</p>
                      </div>
                    </div>
                  )
                : null
            }

            <div className="construction-items">
              {
                this.state.uniqueBasket.map((basketItem, key) =>
                  <NewProjectCard
                    basketData={basketItem}
                    minusKind={this.minusKind}
                    plusKind={this.plusKind}
                    selectCompany={this.selectCompany}
                    key={key}
                  />
                )
              }
            </div>
          </div>
        </div>

        {
          this.props.requiredBuildingsData && this.props.requiredBuildingsData.length
          ? <>
            <h3 className="step-title">Также Вам потребуются</h3>
            <div className="required-building">
              {
                this.props.requiredBuildingsData.map((building, key) => 
                  <div className='required-building-item' key={key}>
                    <div className="image-card" style={{backgroundImage: `url(${BackendUrl + building.image_path})`}}></div>
                    <div className="bottom">
                      <h4 className="name">{building.name}</h4>
                    </div>
                  </div>
                )
              }
            </div>
          </>
          : null
        }
        
        <TotalsSumBlock
          uniqueBasket={this.state.uniqueBasket}
          category={this.props.categoryData}
        />

        <Button
          size="large"
          className="send-btn"
          type="primary"
          disabled={false}
          loading={false}
          onClick={() => this.props.nextTab(this.state.uniqueBasket)}
        >
          ОТПРАВИТЬ ЗАЯВКУ
        </Button>
      </div>
    )
  }
}

export default TotalApplicationBlock;
