import React, { Component } from "react";
import { Spin, Empty } from "antd";

// Styles
import './typesOfCategories.scss'
import { BackendUrl, getInvestProjectTypes } from "../../../../actions/mainActions";

class TypesOfCategoriesBlock extends Component {
  constructor(props) {
    super(props)
    this.state = {
      typesDataList: [],
      isLoading: true
    }
  }

  componentDidMount() {
    getInvestProjectTypes(this.props.selectedCategoryId).then(response => {
      this.setState({
        typesDataList: response.data.data,
        isLoading: false
      })
    })
  }

  render() {
    return (
      <>
        <h3 className="step-title">Выберите тип</h3>
        <div className="step-cards">
          {
            !this.state.isLoading
            ? <div className="types-list">
              {
                this.state.typesDataList.length > 0 ? this.state.typesDataList.map(type => (
                  <button key={type.id} onClick={() => this.props.nextTab(type)} className="image-card">
                    <span>{type.name}</span>
                    <img src={`${BackendUrl}${type.image_path}`} alt={type.name}/>
                  </button>
                )) : <Empty description={'Нет данных'} style={{gridColumn: '1 / 5'}}/>
              }
            </div>
            : <Spin/>
          }
        </div>
      </>
    )
  }
}

export default TypesOfCategoriesBlock;
