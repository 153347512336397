import React, { Component } from "react";
import { Form, Input, Button, Row, Col, Select } from "antd";
import { YMaps, Map, ZoomControl, GeoObject } from "react-yandex-maps";


// Icons and Images
import { ReactComponent as SearchIcon } from "../../../../assets/images/icons/search.svg";

// Styles
import './choosingLandPlotBlock.scss'

const {Option} = Select;

class ChoosingLandPlotBlock extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstStepShowType: 'plot-search',
      searchCoverge: 'all-of-kazakhstan'
    }
  }

  map = React.createRef();
  ymaps = React.createRef();

  firstStepShow(type) {
    this.setState({
      firstStepShowType: type
    })
  }


  landPlotsList = [
    {
      price: 980000000,
      region: 'Участок 34 га',
      location: 'Алматы, Алатауский р-н, мкр Рахат',
      img: '../../../..assets/images/tmp/plot-search/1.jpg',
      terrainList: [
        'КХ (крестьянское/фермерское хозяйство)',
        'Сервитут',
        'Инфраструктура',
      ]
    },
    {
      price: 1504000000,
      region: 'Участок 1077 соток',
      location: 'Алматы, Медеуский р-н, Алатауская трасса',
      img: '../../../..assets/images/tmp/plot-search/2.jpg',
      terrainList: [
        'МЖС (многоэтажное жилищное строительство)',
        'Сервитут',
        'Инфраструктура',
      ]
    },
    {
      price: 120000000,
      region: 'Участок  2000 соток',
      location: 'Алматы, мкр Нур Алатау, Жулдыз',
      img: '../../../..assets/images/tmp/plot-search/3.jpg',
      terrainList: [
        'Иное целевое назначение',
        'Сервитут',
        'Инфраструктура',
      ]
    }
  ]

  btns = [
    {
      name: 'В поиске земельного участка',
      value: 'plot-search'
    },
    {
      name: 'Указать кадастровый номер участка или его адрес',
      value: 'cadastral-number'
    }
  ]

  addSearchControlEvents = () => {
    const map = this.map.current;
    const ymaps = this.ymaps.current;

    const searchControl = new ymaps.control.SearchControl({
      options: {
        float: "left",
        floatIndex: 300,
        provider: "yandex#search",
        geoObjectStandardPreset: "islands#blueDotIcon",
        placeholderContent: "Поиск мест и адресов",
        maxWidth: 320,
        size: "large"
      }
    });
    map.controls.add(searchControl);

    searchControl.events.add("resultselect", function (e) {
      const searchCoords = searchControl.getResponseMetaData().SearchResponse
        .Point.coordinates;
      const display = searchControl.getResponseMetaData().SearchResponse
        .display;

      //console.log(searchControl.getResponseMetaData());

      if (display && display === "multiple") {
        map.setCenter([searchCoords[1], searchCoords[0]], 11);
      }
    });
  };

  render() {
    const formatToCurrnecy = number => new Intl.NumberFormat('ru-RU').format(number) + ' тг'

    return (
      <>
        <h3 className="step-title">Есть ли у Вас земельный участок?</h3>
        <div className="select-region">
          <Row style={{ gridGap: "30px", marginBottom: '35px' }}>
            {
              this.btns.map(btn => 
                <Button
                  type={
                    this.state.firstStepShowType !== btn.value
                    ? 'outlined'
                    : 'primary'
                  }
                  onClick={() => this.state.firstStepShowType !== btn.value
                    ? this.firstStepShow(btn.value)
                    : this.firstStepShow(null)
                  }
                  size="large"
                >
                  { btn.name }
                </Button>
              )
            }
          </Row>

          {
            this.state.firstStepShowType === 'cadastral-number'
            ? <Form
              onFinish={() => null}
              ref={this.formRef}
              className="regForm select-region-form"
            >
              <Row style={{alignItems: 'center', marginBottom: '25px'}}>
                <Col span={5}>
                  <h4>Кадастровый номер</h4>
                </Col>
                <Col span={10}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Введите кадастровый номер",
                      }
                    ]}
                  >
                    <Input placeholder="Введите название проекта" size="large" className="my-ant-input" style={{width: '100%'}}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{alignItems: 'center', marginBottom: '20px'}}>
                <Col span={5}>
                  <h4>Адрес объекта <span style={{color: 'red'}}>*</span></h4>
                </Col>
                <Col span={10}>
                  <Form.Item
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: "Введите адрес объекта",
                      }
                    ]}
                  >
                    <Input placeholder="Введите адрес объекта" size="large" className="my-ant-input" style={{width: '100%'}}/>
                  </Form.Item>
                </Col>
              </Row>

              <div className="select-region-map">
                <YMaps>
                  <Map
                    state={{ center: [51.128207, 71.430420], zoom: 10 }}
                    instanceRef={this.map}
                    onLoad={(ymapsInstance) => {
                      this.ymaps.current = ymapsInstance;
                      this.addSearchControlEvents();
                    }}
                    width="100%"
                    height="345px"
                    modules={["control.SearchControl"]}
                  >
                    <ZoomControl
                      options={{ float: "none", position: { top: 100, right: 10 } }}
                    />

                  <GeoObject
                    // The geometry description.
                    geometry={{
                      type: 'Point',
                      coordinates: [51.112065, 71.646860],
                    }}
                    // Properties.
                    properties={{
                      // The placemark content.
                      // iconContent: 'Я тащусь',
                      hintContent: 'тестовый пин',
                    }}
                    // Options.
                    options={{
                      // The placemark's icon will stretch to fit its contents.
                      preset: 'islands#blackStretchyIcon',
                      // The placemark can be moved.
                      draggable: true,
                    }}
                  />

                  <GeoObject
                    // The geometry description.
                    geometry={{
                      type: 'Point',
                      coordinates: [51.108218, 71.449091],
                    }}
                    // Properties.
                    properties={{
                      // The placemark content.
                      // iconContent: 'Я тащусь',
                      hintContent: 'тестовый пин',
                    }}
                    // Options.
                    options={{
                      // The placemark's icon will stretch to fit its contents.
                      preset: 'islands#blackStretchyIcon',
                      // The placemark can be moved.
                      draggable: true,
                    }}
                  />
                  </Map>
                </YMaps>
              </div>


              <Row className="form-btns" style={{columnGap: '15px'}}>
                {/* <Button
                  size="large"
                  disabled={false}
                  loading={false}
                  className="success-button"
                  onClick={() => this.props.nextTab(1)}
                >
                  ДАЛЕЕ
                </Button> */}

                <Button
                  className="grey"
                  type="outlined"
                  size="large"
                  onClick={() => this.props.nextTab(1)}
                >
                  Пропустить
                </Button>
              </Row>
            </Form>
          : null}

          {
            this.state.firstStepShowType === 'plot-search'
            ? <div className="plotSearch-block">

              <Form
                onFinish={() => null}
                ref={this.formRef}
                className="toolbarInner filterTasks plotSearch-filter"
              >
                <Row style={{gap: "30px"}}>
                  <Select
                    defaultValue="all-of-kazakhstan"
                    className="select_arrow_style_stroke grey-arrow"
                    onChange={(e) => this.setState({searchCoverge: e})}
                    showArrow={false}
                  >
                    <Option value="all-of-kazakhstan">Весь Казахстан</Option>
                    <Option value="task">Алматы</Option>
                    <Option value="problem">Астана</Option>
                  </Select>

                  <Row className="filter-item" style={{columnGap: '12px', alignItems: 'center'}}>
                    <p className="filter-label">Cтоимость, тг</p>

                    <Row style={{columnGap: '6px', alignItems: 'center'}}>
                      <Form.Item name="fsd234432">
                        <Input type="number" placeholder="от" size="large" className="my-ant-input" style={{width: '100%'}}/>
                      </Form.Item>
                      <p className="filter-label">-</p>
                      <Form.Item name="fsdsd342234f">
                        <Input type="number" placeholder="до" size="large" className="my-ant-input" style={{width: '100%'}}/>
                      </Form.Item>
                    </Row>
                  </Row>

                  <Row className="filter-item" style={{columnGap: '12px', alignItems: 'center'}}>
                    <p className="filter-label">Соток</p>

                    <Row style={{columnGap: '6px', alignItems: 'center'}}>
                      <Form.Item name="fsfssf">
                        <Input type="number" placeholder="от" size="large" className="my-ant-input" style={{width: '100%'}}/>
                      </Form.Item>
                      <p className="filter-label">-</p>
                      <Form.Item name="fsdsdf4432">
                        <Input type="number" placeholder="до" size="large" className="my-ant-input" style={{width: '100%'}}/>
                      </Form.Item>
                    </Row>
                  </Row>
                  
                  <Button
                    className="filter-btn"
                    type="primary"
                    style={{ borderRadius: "8px" }}
                    onClick={() => {}}
                  >
                    <Row style={{columnGap: '12px'}}>
                      Найти
                      <SearchIcon style={{ height: '16px', width: '16px', fill: '#fff' }} />
                    </Row>
                  </Button>
                </Row>
              </Form>

              <div className="plotSearch-items">
                {
                  this.landPlotsList.map((plot, index) => (
                    <div className="plotSearch-item" key={index}>
                      <div className="plotSearch-preview" style={{backgroundImage: `url(${require(`../../../../assets/images/tmp/plot-search/${index + 1}.jpg`)})`}}></div>
                      <div className="plotSearch-content">
                        <h4 className="plotSearch-subtitle">{plot.region}</h4>
                        <h3 className="plotSearch-title">{plot.location}</h3>
                        <ul className="plotSearch-list">
                          {
                            plot.terrainList.map((terrain, key) => (
                              <li
                                key={key}
                                className="plotSearch-list-li"
                              >
                                {terrain}
                              </li>
                            ))
                          }
                        </ul>
                      </div>
                      <div className="plotSearch-footer">
                        <p className="plotSearch-price">{formatToCurrnecy(plot.price)}</p>
                        <Button
                          type='primary'
                          className="create-project-select-item-btn"
                          onClick={() => this.props.nextTab(plot)}
                        >
                          Выбрать
                        </Button>
                      </div>
                    </div>
                  ))
                }
                </div>
              </div>
            : null
          }
        </div>

        {
          this.state.firstStepShowType !== 'cadastral-number'
          ? <Button
              className="grey"
              type="outlined"
              size="large"
              onClick={() => this.props.nextTab(1)}
            >
              Пропустить
            </Button>
          : null
        }
      </>
    )
  }
}

export default ChoosingLandPlotBlock;
