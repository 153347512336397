import React, { Component } from "react";

import './createProject.scss'
import Header from "../../../components/uiLanding/layouts/Header/header";
import Footer from "../../../components/uiLanding/layouts/Footer/footer";

import ApplicationSubmissionModal from "../../../components/uiLanding/CreateProject/ApplicationSubmissionModal/applicationSubmissionModal";
import { message } from "antd";
import { createInvestProject } from "../../../actions/mainActions";
import BasketSidebar from "../../../components/uiLanding/CreateProject/BasketSidebar/basketSidebar";
import ChoosingLandPlotBlock from "../../../components/uiLanding/CreateProject/ChoosingLandPlot/choosingLandPlotBlock";
import CreateProjectProgressBar from "../../../components/uiLanding/CreateProject/ProgressBar/progressBar";
import TypesOfCategoriesBlock from "../../../components/uiLanding/CreateProject/TypesOfCategories/typesOfCategories";
import ItemsOfTypesBlock from "../../../components/uiLanding/CreateProject/ItemsOfType/itemsOfType";
import TotalApplicationBlock from "../../../components/uiLanding/CreateProject/TotalApplication/totalApplication";
import ChoosingCategoryBlock from "../../../components/uiLanding/CreateProject/ChoosingCategory/choosingCategoryBlock";

class CreateProject extends Component {
  constructor(props) {
    super(props)
    this.state = {
      basket: [],
      current: 1,
      progress: 1,

      // Step 2
      selectedCategoryId: null,
      selectedCategoryData: null,
      // selectedCategoryData: {
      //   price: 12000000,
      //   region: 'Участок  2000 соток',
      //   location: 'Алматы, мкр Нур Алатау, Жулдыз',
      //   img: '../../../..assets/images/tmp/plot-search/3.jpg',
      //   terrainList: [
      //     'Иное целевое назначение',
      //     'Сервитут',
      //     'Инфраструктура',
      //   ]
      // },

      // Step 3
      selectedTypeId: null,
      selectedTypeName: null,

      // Total Page
      uniqueBasket: [
        // {
        //   data: {
        //     beds: "4-6",
        //     created_at: "2022-12-27T15:00:54.000000Z",
        //     description: "Норвежский домик",
        //     id: 39,
        //     image_path: "/storage/files/mATCbkHJVfMJoTD2Mw8F6Dh1qEqbh6xbiLBlglj7.png",
        //     name: "Норвежский домик",
        //     price: 8000000,
        //     size: 38,
        //     temperature: "-30,+50",
        //   },
        //   count: 1
        // },
        // {
        //   data: {
        //     beds: "4-6",
        //     created_at: "2022-12-27T15:00:54.000000Z",
        //     description: "CABIN",
        //     id: 39,
        //     image_path: "/storage/files/mATCbkHJVfMJoTD2Mw8F6Dh1qEqbh6xbiLBlglj7.png",
        //     name: "CABIN",
        //     price: 8000000,
        //     size: 38,
        //     temperature: "-30,+50",
        //   },
        //   count: 1
        // },
      ],
      requiredBuildingsData: null,

      // Total Sum
      totalSum: 0,

      // Modal
      showModal: false,
      finish: false,
      requestLoading: false
    }
  }

  nextTab = () => {
    let plusSum = this.state.current + 1
    if (this.state.current === 4) plusSum += 1

    this.setState({
      current: this.state.current + 1,
      progress: plusSum
    })
  }

  onCategorySelected = category => {
    this.setState({
      selectedCategoryId: 1,
      selectedCategoryData: category,
      basket: []
    })

    this.nextTab()
  }

  onTypeSelected = type => {
    this.setState({
      selectedTypeId: type.id,
      selectedTypeName: type.name
    })
    this.nextTab()
  }

  onKindSelected = kind => {
    console.log('kind', kind);
    this.setState({
      basket: [...this.state.basket, kind]
    })
  }

  deleteItemFromBasket = item => {
    const index = this.state.basket.indexOf(item)
    this.state.basket.splice(index, 1)
    this.setState({
      basket: this.state.basket
    })
  }

  onGoTotal = (uniqueItemsAndCount, requiredBuildingsData) => {
    this.setState({
      uniqueBasket: uniqueItemsAndCount,
      requiredBuildingsData: requiredBuildingsData
    })

    this.nextTab()
  }

  onFinishWithRequest = dataUser => {
    this.setState({
      requestLoading: false,
      finish: false
    })
    // setTimeout(() => {
    //   // this.props.history.push(`/calculator?costOfObjects=${this.state.totalSum}`)
    //   this.props.history.push(`/`)
    // }, 1500);
    console.log('dataUser', dataUser);
    const formData = new FormData()
    formData.set('name', 'Новый тестовый проект')
    // Нужно подготовить бэк для этих данных
    // formData.set('address', data.address)
    formData.set('cadastral', '09090909090909')

    formData.set('description', 'Краткое описание')
    this.state.uniqueBasket.forEach(item => {
      formData.append(`items[${item.data.id}]`, item.count)
    })
    // Координаты из карты (карта закомментирована, если аппрувнут убрать этот код)
    // if (this.state.coords.length) {
    //   formData.set('coordinates[0]', this.state.coords[0])
    //   formData.set('coordinates[1]', this.state.coords[1])
    // }
    formData.set('address', 'тестовый адрес')
    formData.set('status', 0)
    formData.set('category_id', 1)
    formData.set('fio', dataUser.fullname)
    formData.set('email', dataUser.email)
    formData.set('phone', dataUser.phone)

    console.log(formData);
    
    createInvestProject(formData).then(response => {
      this.setState({
        requestLoading: false,
        finish: true
      })
      // message.success("Проект успешно создан")
      // this.finishedRequest()
      setTimeout(() => {
        this.props.history.push(`/`)
      }, 1500);
    })
  }
  
  render() {
    const changeTab = tabNumber => {
      this.setState({
        current: tabNumber
      })
    }

    const goHideModal = () => {
      this.setState({
        showModal: false
      })
    }

    const goShowModal = (uniqueBasket) => {

      // const data = this.formRef.current.getFieldsValue();

      // if (data.name && data.address) {
      //   this.setState({
      //     showModal: true
      //   })
      // }
      

      // else {
      //   message.error('Необходимо заполнить поля "Название проекта" и "Адрес объекта"')
      //   return;
      // }

      let sum = 0
      uniqueBasket.forEach(item => {
        sum += (item.company ? item.company?.selectWorkPrice : 0) * item.count
      });

      this.setState({
        showModal: true,
        totalSum: sum + this.state.selectedCategoryData?.price
      })
    }

    return (
      <div className='create-project'>
        <Header />
        <div className="create-project-inner">
          <CreateProjectProgressBar
            progress={this.state.progress}
            basketLength={this.state.basket.length}
            changeTab={changeTab}
          />

          <section className="create-project-blocks">
            <div className="container">
              <div className={`states-block`}>

                <div className={`create-project-inner ${this.state.selectedCategoryData !== null && this.state.current !== 5 ? 'active' : ''}`}>
                  {
                    this.state.current === 2 &&
                    <ChoosingCategoryBlock nextTab={() => this.nextTab()} />
                  }
                  {
                    this.state.current === 1 &&
                    <ChoosingLandPlotBlock nextTab={this.onCategorySelected} />
                  }
                  {
                    this.state.current === 3 &&
                    <TypesOfCategoriesBlock
                      nextTab={this.onTypeSelected}
                      selectedCategoryId={this.state.selectedCategoryId}
                    />
                  }
                  {
                    this.state.current === 4 &&
                    <ItemsOfTypesBlock
                      nextTab={this.onTypeSelected}
                      onKindSelected={this.onKindSelected}
                      selectedTypeId={this.state.selectedTypeId}
                      selectedTypeName={this.state.selectedTypeName}
                    />
                  }
                  {
                    this.state.current === 5 &&
                    <TotalApplicationBlock
                      nextTab={goShowModal}
                      uniqueBasket={this.state.uniqueBasket}
                      categoryData={this.state.selectedCategoryData}
                      requiredBuildingsData={this.state.requiredBuildingsData}
                    />
                  }
                </div>

                {/* Basket SideBar */}
                {
                  this.state.current < 5 &&
                  <BasketSidebar
                    nextTab={this.onGoTotal}
                    basketList={this.state.basket}
                    selectedTypeId={this.state.selectedTypeId}
                    selectedCategoryData={this.state.selectedCategoryData}
                    deleteItemFromBasket={this.deleteItemFromBasket}
                  />
                }
              </div>
            </div>
          </section>
        </div>

        <Footer />

        <ApplicationSubmissionModal
          visible={this.state.showModal}
          finish={this.state.finish}
          loading={this.state.requestLoading}
          hide={goHideModal}
          finishRequest={this.onFinishWithRequest}
        />
      </div>
    )
  }
}

export default CreateProject