import React, { Component } from "react";
import { BackendUrl, getInvestProjectRequiredBuildings } from "../../../../actions/mainActions";

// Icons
import {ReactComponent as TemperatureIcon} from "../../../../assets/images/icons/temperature.svg";
import {ReactComponent as SizeIcon} from "../../../../assets/images/icons/building_size.svg";
import {ReactComponent as BedIcon} from "../../../../assets/images/icons/bed.svg";
import { DeleteOutlined } from '@ant-design/icons';

// Styles
import './basketSidebar.scss'
import { Button } from "antd";

class BasketSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false
    }
  }

  onGoBasket = () => {
    this.setState({
      isLoading: true
    })

    const uniqueItemsCountArray = {}
    const uniqueItemsAndCount = []
    const finished = []
    this.props.basketList.forEach(item => { uniqueItemsCountArray[item.id] = (uniqueItemsCountArray[item.id] || 0) + 1; });

    Array.from(new Set(this.props.basketList)).forEach(item => {
      if (!finished.includes(item.id)) {
        uniqueItemsAndCount.push({
          count: uniqueItemsCountArray[item.id],
          data: item
        })
        finished.push(item.id)
      }
    })

    getInvestProjectRequiredBuildings(this.props.selectedTypeId).then(response => {
      this.setState({
        isLoading: false
      })
      this.props.nextTab(uniqueItemsAndCount, response.data.data)
    })
  }

  render() {
    const formatToCurrnecy = number => new Intl.NumberFormat('ru-RU').format(number) + ' тг'

    const totalSum = (list) => {
      let sum = 0;
      list.forEach(item => sum += item.price);
      return formatToCurrnecy(sum + this.props.selectedCategoryData.price);
    }

    return (
      <div className={`basket-block ${this.props.selectedCategoryData !== null ? 'notEmpty' : ''}`}>
        {this.props.notEmpty}
        <button className={`basket-toggle`}>
          <h2 className="title" style={{ fontWeight: 600, marginBottom: '50px' }}>Выбранные проекты</h2>

          <div className="count">
            <p>{this.props.basketList.length + (this.props.selectedCategoryData !== null ? 1 : 0)}</p>
          </div>
        </button>

        <div className="basket-inner">
          <div className="kinds-list">
            {
              this.props.selectedCategoryData !== null && (
                <div className="kind-card plot">
                  <div className="image-card-preview">
                    <img src={`${require(`../../../../assets/images/tmp/plot-search/${1}.jpg`)}`} alt={this.props.selectedCategoryData.location}/>
                  </div>
                  <div className="kind-data">
                    <div className='name blue'>{this.props.selectedCategoryData.region}</div>
                    <div className="center">
                      <p className="location">{this.props.selectedCategoryData.location}</p>
                    </div>
                    <div className='price'>
                      <span>от {formatToCurrnecy(this.props.selectedCategoryData.price)}</span>
                    </div>
                  </div>
                </div>
              )
            }

            {
              this.props.basketList.map((basketItem, key) =>
                <div className="kind-card" key={key}>
                  <div className="image-card-preview">
                    <img src={`${BackendUrl}${basketItem.image_path}`} alt={basketItem.name}/>
                  </div>
                  <div className="kind-data">
                    <div className='name'>{basketItem.name}</div>
                    <div className="center">
                      <div className='beds'><BedIcon/><span>{basketItem.beds}</span></div>
                      <div className='temperature'><TemperatureIcon/><span>{basketItem.temperature}</span></div>
                      <div className='size'><SizeIcon/><span>{basketItem.size} м<sup>2</sup></span></div>
                    </div>
                    <div className='price'>
                      <span>от {formatToCurrnecy(basketItem.price)}</span>
                      <button
                        className="delete-cart-item"
                        onClick={() => this.props.deleteItemFromBasket(basketItem)}
                      >
                        <span>Удалить</span> <DeleteOutlined />
                      </button>
                    </div>
                  </div>
                </div>
              )
            }

            {
              this.props.basketList.length > 0
              ? <h3 className="step-title">Сумма: {totalSum(this.props.basketList)}</h3>
              : null
            }
          </div>
          {
            this.props.basketList.length > 0
            ? <Button
                type="primary"
                size="large"
                className="go-to-basket"
                loading={this.state.isLoading}
                onClick={() => this.onGoBasket()}
              >
                ПРОДОЛЖИТЬ
              </Button>
            : null
          }
        </div>
        
        
      </div>
    )
  }
}

export default BasketSidebar;
