import React, { Component } from "react";
import { Link } from "react-router-dom";

import './successStories.scss'
import Header from "../../../components/uiLanding/layouts/Header/header";
import Footer from "../../../components/uiLanding/layouts/Footer/footer";

// Icons
import {ReactComponent as StarGreen} from "../../../assets/images/icons/star-green.svg";
import { ReactComponent as IconInstagram } from "../../../assets/images/icons/social-networks/instagram.svg";
import { ReactComponent as IconFacebook } from "../../../assets/images/icons/social-networks/facebook.svg";
import { ReactComponent as IconYouTube } from "../../../assets/images/icons/social-networks/youtube.svg";
import { ReactComponent as IconDribbble } from "../../../assets/images/icons/social-networks/dribbble.svg";
import { ReactComponent as IconTwitter } from "../../../assets/images/icons/social-networks/twitter.svg";

class SuccessStorielPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  cases = [
    {
      img: require('../../../assets/images/tmp/cases/1.jpg'),
      title: 'Rixos Aktau',
      obl: 'Мангистауская обл.',
      sum: '67 млрд. тг.'
    },
    {
      img: require('../../../assets/images/tmp/cases/2.jpg'),
      title: 'Караван-Сарай',
      obl: 'Туркестанская обл.',
      sum: '88 млрд. тг.'
    },
    {
      img: require('../../../assets/images/tmp/cases/3.jpg'),
      title: 'гостиница KRich ',
      obl: 'Актюбинская обл.',
      sum: '3,2 млрд.тг.'
    },
    {
      img: require('../../../assets/images/tmp/cases/4.jpg'),
      title: 'Sheraton',
      obl: 'г. Астана',
      sum: '20 млрд. тг.'
    },
    {
      img: require('../../../assets/images/tmp/cases/5.jpg'),
      title: 'Tetys Blu Park',
      obl: 'Мангистауская обл.',
      sum: '47,7 млрд. тг.'
    },
    {
      img: require('../../../assets/images/tmp/cases/6.jpg'),
      title: 'Кедровый бор ',
      obl: 'Акмолинская обл.',
      sum: '2 млрд.тг.'
    }
  ]
  render() {

    return (
      <div className='successStories'>
        <Header />
          <section className="successStories-inner">
            <div className="container">
              <div className="stateSupportMeasures-header">
                <div className="bread-crumbs"><Link to="/">Главная</Link> <span>I</span> <span>Кейсы</span></div>
                <h2 className="landing-title">Кейсы</h2>
              </div>
              
              <div className="successStories-items">
                {
                  this.cases.map((item, key) =>
                    <div className="successStories-item" style={{backgroundImage: `url(${item.img})`}} key={key}>
                      <div className="successStories-textBlock">
                        <h4 className="successStories-title">{ item.title }</h4>
                        <p className="successStories-obl">{ item.obl }</p>
                        <p className="successStories-sum">{ item.sum }</p>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </section>


        <Footer />
      </div>
    )
  }
}

export default SuccessStorielPage