import React, { Component } from "react";
import { Spin, Empty, Button } from "antd";

// Icons
import {ReactComponent as TemperatureIcon} from "../../../../assets/images/icons/temperature.svg";
import {ReactComponent as SizeIcon} from "../../../../assets/images/icons/building_size.svg";
import {ReactComponent as BedIcon} from "../../../../assets/images/icons/bed.svg";
import {ReactComponent as BathIcon} from "../../../../assets/images/icons/bath_icon.svg";

// Styles
import './itemsOfType.scss'
import { BackendUrl, getInvestProjectKinds } from "../../../../actions/mainActions";

class ItemsOfTypesBlock extends Component {
  constructor(props) {
    super(props)
    this.state = {
      kindsDataList: [],
      isLoading: true
    }
  }

  componentDidMount() {
    getInvestProjectKinds(this.props.selectedTypeId).then(response => {
      this.setState({
        kindsDataList: response.data.data,
        isLoading: false
      })
    })
  }

  render() {
    const formatToCurrnecy = number => new Intl.NumberFormat('ru-RU').format(number) + ' тг'

    return (
      <div className="type-progects">
        <h3 className="step-title">{this.props.selectedTypeName}</h3>
        <div className="step-cards">
          {
            !this.state.isLoading ? 
            <div className="kinds-list">
              {
                this.state.kindsDataList.length > 0 ? this.state.kindsDataList.map(kind => (
                  <div key={kind.id}  style={{cursor: 'pointer', }} className="kind-card">
                    <div className="image-card">
                      <img src={`${BackendUrl}${kind.image_path}`} alt={kind.name}/>
                    </div>
                    <div className="kind-data">
                      <div className='name'>{kind.name}</div>
                      <div className="center">
                        <div className='beds'><BedIcon/><span>{kind.beds}</span></div>
                        <div className='temperature'><TemperatureIcon/><span>{kind.temperature}</span></div>
                        <div className='size'><SizeIcon/><span>{kind.size} м<sup>2</sup></span></div>
                        <div className='size'><BathIcon/><span>2</span></div>
                      </div>
                      <div className='price'>
                        <span>от {formatToCurrnecy(kind.price)}</span>
                        <Button
                          className="create-project-select-item-btn"
                          type='primary'
                          onClick={() => this.props.onKindSelected(kind)}
                        >
                          Выбрать
                        </Button>
                      </div>
                    </div>
                  </div>
                )) : <Empty description={'Нет данных'} style={{gridColumn: '1 / 25'}}/>
              }
            </div>
            :
            <Spin/>
          }
        </div>
      </div>
    )
  }
}

export default ItemsOfTypesBlock;
