import { Modal } from "antd";
import React, { Component } from "react";
import { Form, Input, Button } from "antd";


// Icons and Images
import { ReactComponent as IconCheck} from '../../../../assets/images/icons/check-green-with-circle.svg'

// Styles
import './applicationSubmissionModal.scss'

class ApplicationSubmissionModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      projectsData: [],
      total: [],
      loading: false,
      finish: false,
      bin: null
    }
  }

  render() {
    return (
      <Modal
        className="applicationSubmissionModal"
        title={null}
        centered
        footer={null}
        width={"80%"}
        visible={this.props.visible}
        onOk={() => {}}
        onCancel={this.props.hide}
        maskStyle={{
          background: 'rgba(0, 43, 111, 0.8)',
        }}
        afterClose={() => {
          if (this.props.finish) {
            this.goToLandingPage();
          } else {
            return;
          }
        }}
      >
        {
          this.props.finish ? (
            <>
              <IconCheck className="check" />
              <h2 className="modal-title">Заявка Успешно Подана!</h2>
            </>
          ) : (
            <>
              <h2 className="modal-title">Форма заявки</h2>

              <Form
                className="applicationSubmissionModal-regForm"
                name="basic"
                onFinish={this.props.finishRequest}
              >
                <div style={{width: '100%'}}>
                  <h4>БИН/ИИН</h4>
                  <Form.Item
                    name="bin"
                    rules={[
                      {
                        required: true,
                        message: "Введите БИН/ИИН",
                      }
                    ]}
                  >
                    <Input
                      placeholder="БИН/ИИН"
                      size="large"
                      className="my-ant-input"
                      style={{width: '100%'}}
                      formatter={(value) => `${value}`.replace(/^(?:-(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))|(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))))(?:.\d+|)$/)}
                      parser={(value) => value.replace(/^(?:-(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))|(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))))(?:.\d+|)$/, '')}
                      value={this.state.bin}
                      onChange={(e) => {
                        var numberRegex = /^(?:-(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))|(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))))(?:.\d+|)$/;
                        if (numberRegex.test(e.target.value)) {
                          this.setState({ bin: e.target.value })
                        }
                      }}
                    />
                  </Form.Item>

                  <h4>Наименование компании</h4>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Введите наименование компании",
                      }
                    ]}
                  >
                    <Input placeholder="Наименование компании" size="large" className="my-ant-input" style={{width: '100%'}}/>
                  </Form.Item>

                  <h4>ФИО</h4>
                  <Form.Item
                    name="fullname"
                    rules={[
                      {
                        required: true,
                        message: "Введите ФИО",
                      }
                    ]}
                  >
                    <Input placeholder="ФИО" size="large" className="my-ant-input" style={{width: '100%'}}/>
                  </Form.Item>

                  <h4>Номер телефона</h4>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Введите номер телефона",
                      }
                    ]}
                  >
                    <Input placeholder="Номер телефона" size="large" type="tel" className="my-ant-input" style={{width: '100%'}}/>
                  </Form.Item>

                  <h4>E-mail</h4>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Введите E-mail",
                      }
                    ]}
                  >
                    <Input placeholder="E-mail" size="large" type="email" className="my-ant-input" style={{width: '100%'}}/>
                  </Form.Item>
                </div>
                <Form.Item>
                  <Button type="primary" htmlType="submit" size='large'
                    loading={this.props.loading}
                    style={{ width: "250px", float: "left" }}
                  >
                    ОТПРАВИТЬ
                  </Button>
                </Form.Item>
              </Form>
            </>
          )
        }
      </Modal>
    )
  }
}

export default ApplicationSubmissionModal;
