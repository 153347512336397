import React, { Component } from "react";
import { Checkbox, Col, InputNumber, Row, Slider } from "antd";

import { Link } from "react-router-dom";

import './projectModel.scss'
import Header from "../../../components/uiLanding/layouts/Header/header";
import Footer from "../../../components/uiLanding/layouts/Footer/footer";

import {ReactComponent as AutumnIcon} from "../../../assets/images/icons/seasons/autumn.svg";
import {ReactComponent as SummerIcon} from "../../../assets/images/icons/seasons/summer.svg";
import {ReactComponent as SpringIcon} from "../../../assets/images/icons/seasons/spring.svg";
import {ReactComponent as WinterIcon} from "../../../assets/images/icons/seasons/winter.svg";

class ProjectModel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,

      // form inputs
      loadingLevelValue: 0,
      bedValue: 0,
      costOfAccommodationValue: 0,
      marketingValue: 0,
      staffSalaryValue: 0,
      numberOfEmployeesValue: 0,

      // costOfObjects: this.getCostOfObjects(),
      costOfObjects: 0,

      // Season List
      seasonsList: [
        {
          name: 'Весна',
          svg: <SpringIcon />,
          isSelect: false
        },
        {
          name: 'Лето',
          svg: <SummerIcon />,
          isSelect: false
        },
        {
          name: 'Осень',
          svg: <AutumnIcon />,
          isSelect: false
        },
        {
          name: 'Зима',
          svg: <WinterIcon />,
          isSelect: false
        }
      ]
    }
  }

  getCostOfObjects = () => {
    const params = new URLSearchParams(window.location.search);
    const sum = Number(params.get('costOfObjects'));
    return sum ?? 0
  }
      
  render() {
    const formatToCurrnecy = number => new Intl.NumberFormat('ru-RU').format(number) + ' тг'

    const calculateMonthlyIncome = () => {
      let sum =
        this.state.bedValue
        * 30
        * (this.state.loadingLevelValue / 100)
        * (this.state.costOfAccommodationValue * 1000)
      
      const expenses =
        this.state.numberOfEmployeesValue
        * (this.state.staffSalaryValue * 1000)
        + (this.state.marketingValue * 1000)
      
      sum -= expenses
      return sum;
    }

    const calculateIncomePerSeason = () => {
      let count = this.state.seasonsList.filter(season => season.isSelect === true).length;
      return calculateMonthlyIncome() * (count * 3);
    }
      
    const calculatePaybackPeriod = () => {
      let dates =
        (this.state.costOfObjects * 1000)
        / calculateIncomePerSeason()

      let years = Math.trunc(dates)

      let datesString = ''

      if (years > 0) {
        if (years === 1) datesString += `${years} год `
        else if (years < 5) datesString += `${years} года `
        else datesString += `${years} лет `
      }

      let months = Math.trunc((dates - years) * 10)

      if (months > 0) {
        if (months === 1) datesString += `${months} месяц `
        else if (months < 5) datesString += `${months} месяца `
        else datesString += `${months} месяцев `
      }


      if (months <= 0 && years <= 0 && calculateIncomePerSeason() === 0) return
      if (months === 0 && years === 0) return '1 месяц'
      return datesString;
    }

    const supportMeasuresList = [
      {
        title :'Консультации по анализу конкурентной среды',
        link: 'https://g4b.kz/g4b/passport?service_id=26'
      },
      {
        title :'Консультация по анализу плана продвижения товаров, работ, услуг, изучению типов спроса',
        link: 'https://g4b.kz/g4b/passport?service_id=28'
      },
      {
        title :'Консультация по разработке бизнес-плана',
        link: 'https://g4b.kz/g4b/passport?service_id=34'
      },
      {
        title :'Консультация по ценовой политике',
        link: 'https://g4b.kz/g4b/passport?service_id=33'
      },
      {
        title :'Разработка бизнес-плана для кредитования и получение мер государственной поддержки',
        link: 'https://g4b.kz/g4b/passport?service_id=32'
      },
      {
        title :'Разработка проектов договоров в электронном формате',
        link: 'https://g4b.kz/g4b/passport?service_id=48'
      },
      {
        title :'Консультации по регистрации контрольно-кассовых машин и онлайн касс',
        link: 'https://g4b.kz/g4b/passport?service_id=153'
      },
      {
        title :'Консультация по ведению бухгалтерского учета',
        link: 'https://g4b.kz/g4b/passport?service_id=10'
      },
      {
        title :'Консультация по вопросам налогового законодательства и законодательства, регулирующего бухгалтерскую деятельность предприятия',
        link: 'https://g4b.kz/g4b/passport?service_id=11'
      },
      {
        title :'Консультация по подготовке и сдаче налоговых и статистических отчетов',
        link: 'https://g4b.kz/g4b/passport?service_id=9'
      },
      {
        title :'Консультация по составлению дополнительных отчетов по финансовой деятельности для сторонних организаций',
        link: 'https://g4b.kz/g4b/passport?service_id=16'
      },
      {
        title :'Подготовка налоговых и статистических отчетов',
        link: 'https://g4b.kz/g4b/passport?service_id=13'
      },
      {
        title :'Консультация по работе с программой «1С-Бухгалтерия»',
        link: 'https://g4b.kz/g4b/passport?service_id=15'
      },
      {
        title :'Консультация по вопросам обработки первичных документов, начисления налогов и других отчислений в бюджет',
        link: 'https://g4b.kz/g4b/passport?service_id=12'
      },
      {
        title :'Консультации по получению лицензий и разрешительных документов с их правовой поддержкой',
        link: 'https://g4b.kz/g4b/passport?service_id=50'
      },
      {
        title :'Подготовка документов к регистрации, перерегистрации, ликвидации субъекта предпринимательства',
        link: 'https://g4b.kz/g4b/passport?service_id=54'
      },
      {
        title :'Консультация в области гражданского, в области трудового и в области предпринимательского права',
        link: 'https://g4b.kz/g4b/passport?service_id=49'
      },
      {
        title :'Консультационные услуги по вопросу организации и ведения предпринимательской деятельности, сопровождению в институтах развития по вопросам получения мер государственной поддержки',
        link: 'https://g4b.kz/g4b/passport?service_id=55'
      },
      {
        title: 'Консультация по вопросам открытия и ведения бизнеса',
        link: 'https://g4b.kz/g4b/passport?service_id=147'
      },
      {
        title: 'Консультация по государственным программам поддержки бизнеса',
        link: 'https://g4b.kz/g4b/passport?service_id=143'
      }
    ]

    return (
      <div className='projectModel'>
        <Header />
          <section className="projectModel-inner">
            <div className="container">
            <div className="stateSupportMeasures-header">
                <div className="bread-crumbs"><Link to="/">Главная</Link> <span>I</span> <span>Моделирование бизнес-проекта</span></div>
                <h2 className="landing-title">МОДЕЛИРОВАНИЕ БИЗНЕС-ПРОЕКТА</h2>
              </div>
              

              <h3 className="step-title">Времена года, в которые его проект будет работать</h3>
              <div className="seasonsCheckbox">
                {
                  this.state.seasonsList.map((season, idx) => (
                    <Checkbox
                      key={idx}
                      className="seasonsCheckbox-item"
                      style={{marginBottom: '32px', marginLeft: '0px'}}
                      checked={season.isSelect}
                      onChange={(e) => {
                        let newValue = this.state.seasonsList;
                        newValue[idx].isSelect = e.target.checked

                        this.setState({
                          showFormRequisites: newValue,
                        });
                      }}
                    >
                      <div className="seasonsCheckbox-content">
                        <div className="seasonsCheckbox-block">
                          { season.svg }
                        </div>
                        <div className="seasonsCheckbox-label">{ season.name }</div>
                      </div>
                    </Checkbox>
                  ))
                }
              </div>


              <div
                className="regForm select-region-form"
                style={{marginBottom: '70px'}}
              >
                
                <div className="percentages">
                  <Row style={{alignItems: 'center', justifyContent: 'space-between'}}>
                    <h3 className="step-title">Уровень загрузки номерного фонда</h3>


                    <Row style={{width: '125px', alignItems: 'center', justifyContent: 'space-between', columnGap: '10px'}}>
                      <InputNumber
                        className="percentages-input"
                        defaultValue={100}
                        min={0}
                        max={100}
                        value={this.state.loadingLevelValue}
                        onChange={(e) => {
                          this.setState({ loadingLevelValue: e })
                        }}
                      />
                      <h3 className="percentages-title">%</h3>
                    </Row>
                  </Row>
                  <Slider
                    min={1}
                    max={100}
                    onChange={(e) => this.setState({ loadingLevelValue: e })}
                    value={this.state.loadingLevelValue}
                  />
                </div>

                <div className="calculator-form">
                  <Row style={{alignItems: 'center', columnGap: '35px', marginBottom: '25px'}}>
                    <Col span={6}>
                      <h4>Укажите стоимость объектов, в тыс. тенге</h4>
                    </Col>
                    <Col span={5}>
                      <InputNumber
                        placeholder="Введите количество"
                        className="my-ant-input"
                        style={{width: '100%'}}
                        value={this.state.costOfObjects}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={(e) => {
                          this.setState({ costOfObjects: e })
                        }}
                      />
                    </Col>
                    <Col span={3}>
                      <h4>тыс. тенге</h4>
                    </Col>
                  </Row>

                  <Row style={{alignItems: 'center', columnGap: '35px', marginBottom: '25px'}}>
                    <Col span={6}>
                      <h4>Объем номерного фонда, койкомест</h4>
                    </Col>
                    <Col span={5}>
                      <InputNumber
                        placeholder="Введите количество"
                        className="my-ant-input"
                        style={{width: '100%'}}
                        value={this.state.bedValue}
                        onChange={(e) => {
                          this.setState({ bedValue: e })
                        }}
                      />
                    </Col>
                    <Col span={3}>
                      <h4>койкомест</h4>
                    </Col>
                  </Row>


                  <Row style={{alignItems: 'center', columnGap: '35px', marginBottom: '25px'}}>
                    <Col span={6}>
                      <h4>Укажите оценочную стоимость размещения, в тыс. тенге</h4>
                    </Col>
                    <Col span={5}>
                      <InputNumber
                        placeholder="Введите количество"
                        className="my-ant-input"
                        style={{width: '100%'}}
                        value={this.state.costOfAccommodationValue}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={(e) => {
                          this.setState({ costOfAccommodationValue: e })
                        }}
                      />
                    </Col>
                    <Col span={3}>
                      <h4>тыс. тенге</h4>
                    </Col>
                  </Row>

                  <Row style={{alignItems: 'center', columnGap: '35px', marginBottom: '25px'}}>
                    <Col span={6}>
                      <h4>Укажите оценочные затраты на маркетинг, рекламу и благоустрогйство в месяц, в тыс. тенге</h4>
                    </Col>
                    <Col span={5}>
                      <InputNumber
                        placeholder="Введите количество"
                        className="my-ant-input"
                        style={{width: '100%'}}
                        value={this.state.marketingValue}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={(e) => {
                          this.setState({ marketingValue: e })
                        }}
                      />
                    </Col>
                    <Col span={3}>
                      <h4>тыс. тенге</h4>
                    </Col>
                  </Row>

                  <Row style={{alignItems: 'center', columnGap: '35px', marginBottom: '25px'}}>
                    <Col span={6}>
                      <h4>Укажите среднюю зарплату персонала, в тыс. тенге</h4>
                    </Col>
                    <Col span={5}>
                      <InputNumber
                        placeholder="Введите количество"
                        className="my-ant-input"
                        style={{width: '100%'}}
                        value={this.state.staffSalaryValue}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={(e) => {
                          this.setState({ staffSalaryValue: e })
                        }}
                      />
                    </Col>
                    <Col span={3}>
                      <h4>тыс. тенге</h4>
                    </Col>
                  </Row>

                  <Row style={{alignItems: 'center', columnGap: '35px'}}>
                    <Col span={6}>
                      <h4>Укажите количество работников, в ед.</h4>
                    </Col>
                    <Col span={5}>
                      <InputNumber
                        placeholder="Введите количество"
                        className="my-ant-input"
                        style={{width: '100%'}}
                        value={this.state.numberOfEmployeesValue}
                        onChange={(e) => {
                          this.setState({ numberOfEmployeesValue: e })
                        }}
                      />
                    </Col>
                    <Col span={3}>
                      <h4>человек</h4>
                    </Col>
                  </Row>
                </div>

                <div className="calculator-total">
                  <Row  className="calculator-total-item">
                    <Col span={11}>
                      <h4 className="title">Ожидаемый доход в месяц</h4>
                    </Col>
                    <Col span={7}>
                      <h4 className="price">{ formatToCurrnecy(calculateMonthlyIncome()) }</h4>
                    </Col>
                  </Row>

                  <Row  className="calculator-total-item">
                    <Col span={11}>
                      <h4 className="title">Ожидаемая прибыль в сезон</h4>
                    </Col>
                    <Col span={7}>
                      <h4 className="price">{ formatToCurrnecy(calculateIncomePerSeason()) }</h4>
                    </Col>
                  </Row>

                  <Row  className="calculator-total-item">
                    <Col span={11}>
                      <h4 className="title">Ожидаемый период окупаемости</h4>
                    </Col>
                    <Col span={7}>
                      <h4 className="price">{ calculatePaybackPeriod() }</h4>
                    </Col>
                  </Row>
                </div>

                <p className="calculator-description">Данный сервис предназначен для формирования общей осведомленности о порядке моделирования бизнес-инициатив и не может использоваться для принятия итоговых инвестиционных решений, т.к. не учитывает ряд производственных, вспомогательных, налоговых, амортизационных и иных затрат, а также динамику спроса, инфляции и множество иных факторов, оказывающих влияние на условия ведения бизнеса.</p>

                <h3 className="step-title supportMeasures-title">Меры поддержки для бизнеса</h3>
                <div className="supportMeasures">
                  {
                    supportMeasuresList.map((item, key) => (
                      <a className="supportMeasures-item" key={key} href={item.link} target="_blank">
                        <p className="supportMeasures-text">{item.title}</p>
                      </a>
                    ))
                  }
                </div>
              </div>
            </div>
          </section>

        <Footer />
      </div>
    )
  }
}

export default ProjectModel