import React, { Component } from "react";
import { ReactComponent as First } from "../../assets/images/tmp/1_stat.svg";
import { ReactComponent as Second } from "../../assets/images/tmp/2_stat.svg";
import { ReactComponent as Third } from "../../assets/images/tmp/3_stat.svg";
import { ReactComponent as Fourth } from "../../assets/images/tmp/4_stat.svg";
import './investment.scss'

class MainPanelInvestment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: []
    }
  }

  render() {
    return (
      <main className='investment-stats'>
        <div className="title">
          <h1>СТАТИСТИКА ПО РАЗВИТИЮ ТУРИЗМА В КАЗАХСТАНЕ</h1>
          <p>Данные взяты из системы <a href="https://tourstat.kz/touch-bord" target="_blank" rel="noopener noreferrer">"Tourstat.kz"</a></p>
        </div>
        <section className="investment-stats__item">
          <First/>
        </section>
        <section className="investment-stats__item">
          <Second/>
        </section>
        <section className="investment-stats__item">
          <Third/>
        </section>
        <section className="investment-stats__item">
          <Fourth/>
        </section>
      </main>
    )
  }
}

export default MainPanelInvestment;
