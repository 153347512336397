import axios from "axios";
import { message } from "antd";
//import request from "../../modules/request";

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response) {
        if (error.response.status === 401) {
            message.error("Ошибка авторизации, Повторно войдите в систему", 5);
            setTimeout(() => {
                window.location.replace("/auth");
            }, 2000)

        } else if (error.response.status === 404) {
            message.warning("Нет данных, по вашему запросу", 5);
        } else if (error.response.data) {
            Object.values(error.response.data).forEach(error => message.error(error))
        }
    }
    return Promise.reject(error);
});
const token = localStorage.getItem("token");
let config = {
    headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token ?? ""}`
    }
};
let configFormData = {
    headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${token ?? ""}`
    }
};
const configAuth = {
    headers: {
        "Content-Type": "application/json"
    }
};
export const BackendUrl = process.env.REACT_APP_BACK_END;

export const getDashboardData = () => {
    return axios.get(`${BackendUrl}/api/v1/dashboard`, config);
}

export const userLogin = (data) => {
    return axios.post(`${BackendUrl}/api/sanctum/token`, data, configAuth).then((res) => {
        localStorage.setItem("token", res.data.token);
        config.headers.Authorization = `Bearer ${res.data.token}`;
        configFormData.headers.Authorization = `Bearer ${res.data.token}`;
        return res;
    });
};

export const userRegister = (data) => {
    return axios.post(`${BackendUrl}/api/v1/register/user`, data, configAuth);
};
export const getPagination = () => {
    return axios.get(`${BackendUrl}/api/v1/projects/paginated`,config);
};
export const agentRegister = (data) => {
    return axios.post(`${BackendUrl}/api/v1/register/contractor`, data, configAuth);
};
export const investorRegister = (data) => {
    return axios.post(`${BackendUrl}/api/v1/register/investor`, data, configAuth);
};

export const checkBinRequest = (bin) => {
    return axios.get(`${BackendUrl}/api/contractor/bin?bin=${bin}`);
};

export const getProjects = () => {
    return axios.get(`${BackendUrl}/api/v1/projects`, config);
};

export const getProjectsWithFilter = (filter) => {
    return axios.get(`${BackendUrl}/api/v1/projects/filter`, {
        ...config,
        params: filter
    })
}

export const getProjectsByName = (name = '') => {
    return axios.get(`${BackendUrl}/api/v1/projects/filter?name=${name}`, config);
};

export const getContragents = () => {
    return axios.get(`${BackendUrl}/api/contractor`, config);
};

export const getTaskWithFilter = (filter) => {
    return axios.get(`${BackendUrl}/api/v1/tasks/filter`, {
        ...config,
        params: filter
    })
}

export const getArchiveData = (params) => {
    return axios.get(`${BackendUrl}/api/v1/tasks/status/archive`, {
        ...config,
        params
    });
};
/* export const getArchiveData = () => {
    request({
      url: `${BackendUrl}/api/v1/tasks/status/archive`,
      method: "get",
    });
} */

export const changeProjectStatus = (data) => {
    return axios.post(`${BackendUrl}/api/v1/projects/status`, data, config);
}

export const postProjectsComment = (data) => {
    return axios.post(`${BackendUrl}/api/v1/project-comments`, data, config);
};
export const getProjectComments = (projectId) => {
    return axios.get(`${BackendUrl}/api/v1/project-comments/project/${projectId}`,config)
}
export const getCommentById = (projectId) => {
    return axios.get(`${BackendUrl}/api/v1/project-comments/${projectId}`,config)
}
export const editProjectComment = (editCommentId,data) => {
    return axios.put(`${BackendUrl}/api/v1/project-comments/${editCommentId}`, data, config);
}
export const deleteProjectComment = (commentId) => {
    return axios.delete(`${BackendUrl}/api/v1/project-comments/${commentId}`, config);
};


export const getContractorUsers = (organization_ids, page=1) => {
    return axios.get(`${BackendUrl}/api/v1/contractors/users?${organization_ids}&page=${page}`,config)
}


export const getProject = (id) => {
    return axios.get(`${BackendUrl}/api/v1/projects/${id}`, config);
};
export const postProjects = (data) => {
    return axios.post(`${BackendUrl}/api/v1/projects`, data, config);
};
export const putProjects = (data, id) => {
    return axios.put(`${BackendUrl}/api/v1/projects/${id}`, data, config);
};
export const deleteProjects = (id) => {
    return axios.delete(`${BackendUrl}/api/v1/projects/${id}`, config);
};
export const getMembers = () => {
    return axios.get(`${BackendUrl}/api/v1/users/all`, config);
};
export const getUsersFromAllOrganisations = () => {
    return axios.get(`${BackendUrl}/api/v1/users/all-organizations`, config);
};
export const getTasks = () => {
    return axios.get(`${BackendUrl}/api/v1/tasks`, config);
};
export const getTask = (id) => {
    return axios.get(`${BackendUrl}/api/v1/tasks/${id}`, config);
};
export const deleteTask = (id) => {
    return axios.delete(`${BackendUrl}/api/v1/tasks/${id}`, config);
};
export const postTasks = (data) => {
    return axios.post(`${BackendUrl}/api/v1/tasks`, data, config);
};
export const changeTaskStatus = (taskId, statusId) => {
    return axios.post(
        `${BackendUrl}/api/v1/tasks/status`,
        {
            id: taskId,
            status_id: statusId
        },
        config
    )
}
export const putTask = (data, id) => {
    return axios.put(`${BackendUrl}/api/v1/tasks/${id}`, data, config);
};
export const getProfile = () => {
    return axios.get(`${BackendUrl}/api/v1/profile`, config);
};
export const getProfileById = (id) => {
    return axios.get(`${BackendUrl}/api/v1/profile/${id}`, config);
};
export const getNotifs = () => {
    return axios.get(`${BackendUrl}/api/v1/notification`, config);
};
export const readNotif = (id) => {
    return axios.put(`${BackendUrl}/api/v1/notification/${id}`, {}, config);
};
export const deleteNotif = (id) => {
    return axios.delete(`${BackendUrl}/api/v1/notification/${id}`, config);
};
export const putProfile = (data) => {
    return axios.put(`${BackendUrl}/api/v1/profile`, data, config);
};

export const getFiles = () => {
    return axios.get(`${BackendUrl}/api/v1/file-storage`, config);
};

export const getFile = (idsArray) => {
    return axios.post(`${BackendUrl}/api/v1/file-storage/get`, { ids: idsArray }, config);
};
export const deleteFile = (id) => {
    return axios.get(`${BackendUrl}/api/v1/file-storage/delete/${id}`, config);
};

export const addMapObject = (data) => {
    return axios.post(`${BackendUrl}/api/v1/map-objects`, data, config);
};
export const addFile = (data) => {
    return axios.post(`${BackendUrl}/api/v1/file-storage/add`, data, configFormData);
};

export const testAdd = (data) => {
    return axios.post(`${BackendUrl}/api/test`, data, configFormData);
};

export const getAllMapObjects = () => {
    return axios.get(`${BackendUrl}/api/v1/map-objects`, config);
};

export const getAllTasksMapObjects = () => {
    return axios.get(`${BackendUrl}/api/v1/tasks/map-objects`, config);
};

export const getContractorsList = (page, per_page) => {
    return axios.get(`${BackendUrl}/api/v1/register/contractor?per_page=${per_page}&page=${page}`, config);
};
export const getRequsetUsersList = (page, per_page) => {
    return axios.get(`${BackendUrl}/api/v1/register/user?per_page=${per_page}&page=${page}`, config);
};

export const acceptRequsetUser = (id) => {
    return axios.put(`${BackendUrl}/api/v1/register/user/accept/${id}`, {}, config);
};
export const rejectRequsetUser = (id) => {
    return axios.put(`${BackendUrl}/api/v1/register/user/reject/${id}`, {}, config);
};
export const acceptContractor = (id) => {
    return axios.put(`${BackendUrl}/api/v1/register/contractor/accept/${id}`, {}, config);
};
export const rejectContractor = (id) => {
    return axios.put(`${BackendUrl}/api/v1/register/contractor/reject/${id}`, {}, config);
};

export const getAllUsersList = (page, per_page) => {
    return axios.get(`${BackendUrl}/api/v1/user/all?per_page=${per_page}&page=${page}`, config);
};

export const getAllContrAgentsList = (page, per_page) => {
    return axios.get(`${BackendUrl}/api/v1/contractors/all?per_page=${per_page}&page=${page}`, config);
};

export const switchAdminUser = (id) => {
    return axios.put(`${BackendUrl}/api/v1/user/${id}`, {}, config);
};
export const deleteUser = (id) => {
    return axios.delete(`${BackendUrl}/api/v1/user/${id}`, config);
};

export const updateStatus = (data) => {
    return axios.post(`${BackendUrl}/api/v1/tasks/status`, data, config);
};

export const newOrdering = (data) => {
    return axios.post(`${BackendUrl}/api/v1/tasks/ordering`, data, config);
};

export const addEvent = (data) => {
    return axios.post(`${BackendUrl}/api/v1/event`, data, config);
};

export const deleteEvent = (id) => {
    return axios.delete(`${BackendUrl}/api/v1/event/${id}`, config);
};

export const getEvents = () => {
    return axios.get(`${BackendUrl}/api/v1/event`, config);
};

export const getProblems = (page, per_page) => {
    return axios.get(`${BackendUrl}/api/v1/problem/all?per_page=${per_page}&page=${page}`, config);
};

export const getProblemsHelp = (page, per_page) => {
    return axios.get(`${BackendUrl}/api/v1/problem/need_help?per_page=${per_page}&page=${page}`, config);
};

export const getProblemsResolved = (page, per_page) => {
    return axios.get(`${BackendUrl}/api/v1/problem/resolved?per_page=${per_page}&page=${page}`, config);
};

export const getProblemsProgress = (page, per_page) => {
    return axios.get(`${BackendUrl}/api/v1/problem/in_progress?per_page=${per_page}&page=${page}`, config);
};

export const getProblemById = (id) => {
    return axios.get(`${BackendUrl}/api/v1/problem/${id}`, config);
}

export const addProblem = (data) => {
    return axios.post(`${BackendUrl}/api/v1/problem/create`, data, config);
};

export const deleteProblem = (id) => {
    return axios.delete(`${BackendUrl}/api/v1/problem/${id}`, config);
};

export const changeStatusProblem = (id, data) => {
    return axios.put(`${BackendUrl}/api/v1/problem/${id}`, data, config);
};

export const resetPasswordByEmail = (data) => {
    return axios.post(`${BackendUrl}/api/v1/reset-password`, data);
};

export const getTags = (type) => {
    return axios.get(`${BackendUrl}/api/v1/tags/${type}`, config);
};

export const getTypes = (type) => {
    return axios.get(`${BackendUrl}/api/v1/directory/${type}`, config);
};

export const getChangelog = (params) => {
    return axios.post(`${BackendUrl}/api/v1/action-log`, params, config);
};

export const getDictionaryList = () => {
    return axios.get(`${BackendUrl}/api/v1/directory`, config);
}

export const addDictionary = (params) => {
    return axios.post(`${BackendUrl}/api/v1/directory/create`, params, config);
}

export const changePassword = (params) => {
    return axios.post(`${BackendUrl}/api/v1/user/change-password`, params, config);
}

export const globalSearch = (type, text) => {
    return axios.post(`${BackendUrl}/api/v1/search`, { type, text }, config);

}

export const getCompaniesList = () => {
    return axios.get(`${BackendUrl}/api/contractor`, config)
}


export const createTaskComment = data => {
    return axios.post(`${BackendUrl}/api/v1/task-comments`, data, config)
}
export const getTaskComment = task_id => {
    return axios.get(`${BackendUrl}/api/v1/task-comments/task/${task_id}`, config)
}
export const editTaskComment = (comment_id, data) => {
    return axios.put(`${BackendUrl}/api/v1/task-comments/${comment_id}`, data, config)
}
export const deleteTaskComment = comment_id => {
    return axios.delete(`${BackendUrl}/api/v1/task-comments/${comment_id}`, config)
}

export const getLibraryData = (page = 1) => {
    return axios.get(`${BackendUrl}/api/v1/documents?page=${page}`, config)
}
export const getMyLibraryData = (page = 1) => {
    return axios.get(`${BackendUrl}/api/v1/documents/my?page=${page}`, config)
}
export const createNewDocument = data => {
    return axios.post(`${BackendUrl}/api/v1/documents`, data, config)
}
export const deleteDocument = id => {
    return axios.delete(`${BackendUrl}/api/v1/documents/${id}`, config)
}

//  Investment actions
export const getInvestProjects = (page, per_page) => {
    return axios.get(`${BackendUrl}/api/v1/invest-projects?page=${page}&per_page=${per_page}`, config)
}
export const getInvestProjectById = id => {
    return axios.get(`${BackendUrl}/api/v1/invest-projects/${id}`, config)
}
export const createInvestProject = data => {
    return axios.post(`${BackendUrl}/api/v1/invest-projects/request`, data, config)
}
export const changeInvestProject = (id, data) => {
    return axios.put(`${BackendUrl}/api/v1/invest-projects/${id}`, data, config)
}
export const deleteInvestProject = id => {
    return axios.delete(`${BackendUrl}/api/v1/invest-projects/${id}`, config)
}

export const getInvestProjectRegions = () => {
    return axios.get(`${BackendUrl}/api/v1/invest-project-regions`, config)
}
export const getInvestProjectCategories = () => {
    return axios.get(`${BackendUrl}/api/v1/invest-project-categories`, config)
}
export const getInvestProjectTypes = categoryId => {
    return axios.get(`${BackendUrl}/api/v1/invest-project-types/category/${categoryId}`, config)
}
export const getInvestProjectKinds = typeId => {
    return axios.get(`${BackendUrl}/api/v1/invest-project-kinds/type/${typeId}`, config)
}
export const getInvestProjectRequiredBuildings = typeId => {
    return axios.get(`${BackendUrl}/api/v1/invest-project-required-buildings/type/${typeId}`, config)
}

// CallbackRequest actions

export const createCallBackRequest = data => {
    return axios.post(`${BackendUrl}/api/v1/contact-order`, data, config)
}

export const getCallBackRequests = () => {
    return axios.get(`${BackendUrl}/api/v1/contact-order`, config)
}

export const deleteCallBackRequest = (id) => {
    return axios.delete(`${BackendUrl}/api/v1/contact-order/${id}`, config);
};

// Google Calendar actions

export const getGoogleAuthLink = () => {
    return axios.get(`${BackendUrl}/api/v1/google/oauth`, config);
}

export const syncAllEventsWithGoogle = () => {
    return axios.get(`${BackendUrl}/api/v1/event/sync`, config);
}

export const syncEventWithGoogle = (eventId) => {
    return axios.get(`${BackendUrl}/api/v1/event/${eventId}/sync`, config);
}