import React, { Component } from "react";
import { Link } from "react-router-dom";

// Styles
import './progressBar.scss'

class CreateProjectProgressBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    const steps = [1, 2, 3, 4, 5];

    return (
      <div className="create-project-header">
        <div className="container">
        <div className="create-project-titles">
          <div className="bread-crumbs"><Link to="/">Главная</Link> <span>I</span> <span>Создание проекта</span></div>
          <h2 className="landing-title">Создать проект</h2>
        </div>
        <div className="steps">
          {
            steps.map((step, key) => (
              <button
                className={
                  `step
                  ${
                    (
                      step !== 4 ?
                      this.props.progress >= step
                      : this.props.basketLength > 0
                    )
                    ? 'active' : ''
                  }`
                }
                onClick={() => { this.props.progress >= step && this.props.changeTab(step) }}
                key={key}
              />
            ))
          }
        </div>
        </div>
      </div>
    )
  }
}

export default CreateProjectProgressBar;
