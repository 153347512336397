import React from 'react';
import {Switch, Route} from 'react-router-dom';
import MainPanel from '../pages/mainPage/mainPage';
import HomePanel from '../pages/uiLanding/Home/home';
import {Auth} from '../pages/Auth/index';
import LandingProjects from '../pages/uiLanding/Projects/projects';
import CreateProject from '../pages/uiLanding/CreateProject/createProject';
import LandingProjectDetail from '../pages/uiLanding/ProjectDetail/projectDetail';
import LandingFinishedProject from '../pages/uiLanding/FinishedProject/finishedProject';
import stateSupportMeasures from '../pages/uiLanding/StateSupportMeasures/stateSupportMeasures';
import AboutSystem from '../pages/uiLanding/System/System';
import ProjectModel from '../pages/uiLanding/ProjectModel/projectModel';
import SuccessStorielPage from '../pages/uiLanding/SuccessStories/successStories';

export const useRoutes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <MainPanel path='/'/>
      </Route>
      
      <Route>
        <MainPanel path='/main' exact/>
      </Route>
      <Route>
        <Auth path='/auth' component={Auth} exact/>
      </Route>
    </Switch>
  )
}
