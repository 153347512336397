import React, { Component } from "react";
import { Table } from "antd";
// Icons
import {ReactComponent as StarGrey} from "../../../../assets/images/icons/star-grey.svg";
import {ReactComponent as StarGreen} from "../../../../assets/images/icons/star-green.svg";

// Styles
import './totalsBlock.scss'

class TotalsSumBlock extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const formatToCurrnecy = number => new Intl.NumberFormat('ru-RU').format(number) + ' тг'

    const totalSum = () => {
      let sum = 0
      this.props.uniqueBasket.forEach(item => {
        sum += (item.company ? item.company?.selectWorkPrice : 0) * item.count
      });

      return formatToCurrnecy(sum + this.props.category.price)
    }

    const tableColumns = [
      {
        title: "Итого:",
        dataIndex: "typeOfCategory",
        key: "typeOfCategory"
      },
      {
        title: "",
        dataIndex: "name",
        key: "name",
        render: (name, raiting) => (
          name
          ? <div className="title-block">
              <h4 className="title">{name}</h4>
              <div className="stars">
                <StarGreen />
                <StarGreen />
                <StarGreen />
                <StarGrey />
                <StarGrey />
              </div>
            </div>
          : null
        ),
      },
      {
        title: "",
        dataIndex: "production",
        key: "production",
        render: (production) => (
          production
            ? <p className="title-deadlines">Изготовление: <span>{production}</span></p>
            : null
        ),
      },
      {
        title: "",
        dataIndex: "delivery",
        key: "delivery",
        render: (delivery) => (
          delivery
            ? <p className="title-deadlines">Доставка: <span>{delivery}</span></p>
            : null
        ),
      },
      {
        title: "",
        dataIndex: "stateMontage",
        key: "stateMontage"
      },
      {
        title: totalSum(),
        dataIndex: "price",
        key: "price"
      },
    ];

    let tableValues = [
      {
        typeOfCategory: this.props.category.region,
        price: formatToCurrnecy(this.props.category.price),
      },
      {
        id: 1,
        typeOfCategory: 'Геокупол',
        name: 'Интернет-магазине "KazAl"',
        raiting: 3,
        stateMontage: 'Без монтажа',
        production: '7 дней',
        delivery: '1 неделя',
        price: '3 500 000 〒',
      },
      {
        id: 1,
        typeOfCategory: 'Ортокупол',
        name: 'Glamping kaz',
        raiting: 3,
        stateMontage: 'С монтажом',
        production: '10 дней',
        delivery: '3 неделя',
        price: '3 850 000 〒',
      },
    ]

    const newtableValues = () => {
      let list = [
        {
          typeOfCategory: this.props.category.region,
          price: formatToCurrnecy(this.props.category.price),
        }
      ];

      this.props.uniqueBasket.forEach(item => {
        if (item.company) {
          list.push({
            id: 1,
            typeOfCategory: item.data.name,
            name: item.company.name,
            raiting: 3,
            stateMontage: item.company.withMontage ? 'С монтажом' : '	Без монтажа',
            production: item.company.production,
            delivery: item.company.delivery,
            price: formatToCurrnecy(Number(item.company.selectWorkPrice * item.count)),
          })
        }
      });

      tableValues = list;
    }

    newtableValues();

    return (
      <div className="totalsBlock">
        <div className="totalsBlock-body">
          <Table
            columns={tableColumns}
            dataSource={tableValues}
            className="companies-table"
            bordered
            pagination={false}
          />
        </div>
      </div>
    )
  }
}

export default TotalsSumBlock;
