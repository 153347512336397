import { Button } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";

// Icons - Images
import { ReactComponent as Logo } from "../../../../assets/images/icons/logo-for-landing.svg";

// CSS Styles
import './header.scss'

const currUserID = JSON.parse(localStorage.getItem('user'))?.id;


class Header extends Component {
  render() {
    return (
      <header className="header-landing">
        <div className="header-landing-inner">
          <div className="container">
            <Link to='/'>
              <Logo className="header-landing-logo" />
            </Link>

            <div className="header-landing-right">
              <nav className="header-landing-nav">
                <div className="nav-links">
                  <Link to="/about-system" className="header-landing-nav-link">О системе</Link>
                  <Link to="/landing-projects" className="header-landing-nav-link">Инвестпроекты</Link>
                  <Link to="/calculator" className="header-landing-nav-link">КаЛЬКУЛЯТОР</Link>
                  <Link to="/success-stories" className="header-landing-nav-link">Кейсы</Link>
                  <Link to="/state-support-measures" className="header-landing-nav-link">Меры поддержки</Link>
                </div>
                <div className="btn-links">
                  <Link to='/create-projects'>
                    <Button className="header-landing-submit" type="primary" size="large">Подать заявку</Button>
                  </Link>
                  <Link to={currUserID ? '/panel' : '/auth'}>
                    <Button className="header-landing-to-auth">Войти</Button>
                  </Link>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
