import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

// Components
import Header from "../../../components/uiLanding/layouts/Header/header";
import Footer from "../../../components/uiLanding/layouts/Footer/footer";

import { Button, Modal, Form, Input, message } from "antd";
import { projectsFinished } from "../../../actions/projects-finished-list"

// Icons - Images
import { ReactComponent as IconInstagram } from "../../../assets/images/icons/social-networks/instagram.svg";
import { ReactComponent as IconFacebook } from "../../../assets/images/icons/social-networks/facebook.svg";
import { ReactComponent as IconYouTube } from "../../../assets/images/icons/social-networks/youtube.svg";

import { ReactComponent as IconArrowToRight } from "../../../assets/images/icons/arrow-to-right.svg";
import { ReactComponent as IconPhone } from "../../../assets/images/icons/icon-phone-incoming.svg";
import { ReactComponent as IconEmail } from "../../../assets/images/icons/icon-mail.svg";

import { projectsList } from "../../../actions/projects-list"

// CSS Styles
import './home.scss'
import MapProjects from "../../../components/uiLanding/MapProjects/mapProjects";
import { createCallBackRequest } from "../../../actions/mainActions";

const toNext = () => {
  document.querySelectorAll('#projects-landing-slider')[0].scrollBy(545, 0)
}

const toPrev = () => {
  document.querySelectorAll('#projects-landing-slider')[0].scrollBy(0 - 545, 0)
}


class HomePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      requestLoading: false,
      finishedRequest: false,
    }
    this.formRef = React.createRef();
  }

  openModal = () => {
    this.setState({
      modalVisible: true,
    })
  }

  hideModal = () => {
    this.setState({
      modalVisible: false,
    })
  }

  finishCallBackRequest = () => {

    this.setState({
      requestLoading: true,
    })

    const data = this.formRef.current.getFieldsValue();

    this.formRef.current.validateFields().then(res => {
      const formData = new FormData()
      formData.set('fio', data.fio)
      formData.set('email', data.email)
      formData.set('phone', data.phone)

      if (!data.fio || !data.email || !data.phone) {
        message.error('Нужно заполнить все поля')
        return;
      }
      
      createCallBackRequest(formData).then(response => {
        message.success("Заявка успешно создана")

        this.setState({
          requestLoading: false,
          finishedRequest: true,
        })

        setTimeout(() => {
          window.location = window.location.origin
        }, 3000);
      })
    }, err => {
      message.error(err);
      this.setState({
        requestLoading: false,
      })
    }
    )
  }

  onScroll = (event) => {
    this.setState({
      clientX: event.clientX,
      isMouseScrolling: true,
    })

    console.log(`Mouse down:`)
    console.log(event);

    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onMouseUp);
  }

  onMouseMove = (event) => {
    const slider = document.querySelectorAll('#projects-landing-slider')[0];

    console.log(`Mouse move:`)
    console.log(event);

    const originPos = this.state.clientX;
    const newPos = event.clientX;

    slider.scrollLeft -= newPos - originPos;

    this.setState({
      clientX: newPos,
    })
  }

  onMouseUp = (event) => {

    this.setState({
      isMouseScrolling: false,
    })

    console.log(`Mouse up:`)
    console.log(event);

    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
  }

  render() {
    return (
      <main className="home-page">
        <Modal
          className="callBackModal"
          title={null}
          centered
          footer={null}
          bodyStyle={{
            width: '655px',
            height: '637px',
            background: '#FFFFFF',
            borderRadius: '10px',
          }}
          width={655}
          visible={this.state.modalVisible}
          onOk={() => {}}
          onCancel={() => {this.hideModal()}}
          maskStyle={{
            background: 'rgba(0, 43, 111, 0.8)',
          }}
          // afterClose={() => {
          //   if (this.props.finish) {
          //     this.goToLandingPage();
          //   } else {
          //     return;
          //   }
          // }}
        >

            <h2 className="modal-title">ЗАПОЛНИТЕ ДАННЫЕ</h2>
            <Form className="callBackModal__form" ref={this.formRef} onFinish={() => {this.finishCallBackRequest()}}>
              <h4>ФИО</h4>
              <Form.Item
                name="fio"
                rules={[
                  {
                    required: true,
                    message: "Введите ФИО"
                  }
                ]}
              >
                <Input placeholder='Введите' size='large'/>
              </Form.Item>
                <h4>Email</h4>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Введите email"
                    }
                  ]}
                >
                  <Input placeholder='Введите' size='large'/>
                </Form.Item>

                <h4>Номер телефона</h4>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Введите номер телефона"
                    }
                  ]}
                  className="callBackModal__form__phone"
                >
                  <Input placeholder='Введите номер телефона' size='large'/>
                </Form.Item>

                <Form.Item className="callBackModal__form__button">
                  <Button type="primary" htmlType="submit" size='large'
                    disabled={this.state.finishedRequest ? true : false}
                    loading={this.state.requestLoading}
                    style={{ width: "250px", float: "left" }}
                  >
                    ОТПРАВИТЬ ЗАПРОС
                  </Button>
                </Form.Item>
            </Form>


        </Modal>
        <Header />

        <section className="intro">
          <div className="container">
            <div className="intro-inner">
              <div className="intro-inner-left">
                <p className="intro-subtitle">Фонд развития туристических проектов</p>
                <h2 className="intro-title">VIEW PROJECT <br /> INVEST</h2>
                <Link to='/create-projects'>
                  <Button className="intro-submit-btn">Подать заявку</Button>
                </Link>
              </div>

              <div className="intro-socialNetworks">
                <a className="intro-socialNetworks-link" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/kazakhtourism_official/">
                  <IconInstagram />
                </a>
                <a className="intro-socialNetworks-link" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/TraveltoKazakhstan/">
                  <IconFacebook />
                </a>
                <a className="intro-socialNetworks-link" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/kazakhstantravel">
                  <IconYouTube />
                </a>
              </div>
            </div>
          </div>
        </section>


        <section className="map-landing">
          <div className="container">
            <h2 className="landing-title">КАРТА ПРОЕКТОВ</h2>
            <MapProjects page="home" data={projectsList} />
          </div>
        </section>

        <section className="projects-landing">
          <div className="container">
            <div className="projects-landing-header">
              <h2 className="landing-title">Реализованные проекты</h2>
              <div className="projects-landing-control">
                <button onClick={toPrev}>
                  <IconArrowToRight />
                </button>
                <button onClick={toNext}>
                  <IconArrowToRight />
                </button>
              </div>
            </div>

            <div className={this.state.isMouseScrolling ? "projects-landing-slider" : "projects-landing-slider scrollSmooth"} id="projects-landing-slider"
            ref={this.attachScroller}
            onMouseDown={this.onScroll}>
              { projectsFinished.map(product => (
                <Link className="projects-landing-item" to={`/landing-finished-project/${product.id}`} key={product.id}>
                  <div className="projects-landing-item-bg" style={{backgroundImage: `url(${require('../../../assets/images/tmp/finished-projects/project-' + product.id + '.jpg')})`}}></div>
                  <h4 className="projects-landing-item-title"><span>{ product.title }</span></h4>
                </Link>
              )) }
            </div>
          </div>
        </section>

        <section className="becomePartner">
          <div className="becomePartner-inner">
            <h2 className="landing-title">СТАТЬ ПАРТНЁРОМ</h2>
            <p className="becomePartner-description">Если Вы нашли интересный проект для Вас, просим обратиться<br/> по номеру или написать на наш электронный адрес. <br/> Либо оставить заявку на обратный звонок.</p>

            <h3 className="becomePartner-contacts-title">Контакты Call-центра</h3>
            <a className="becomePartner-contacts-link-block" href="tel:+77018884728" target="_blank" rel="noopener noreferrer">
              <IconPhone />
              <p className="becomePartner-contacts-link">+7(701) 888 47 28</p>
            </a>
            <a className="becomePartner-contacts-link-block" href="mailto:y.adilkhanov@gmail.com" target="_blank" rel="noopener noreferrer">
              <IconEmail />
              <p className="becomePartner-contacts-link">y.adilkhanov@gmail.com</p>
            </a>
              <Button className="becomePartner-submit-btn" type="primary" onClick={() => {this.openModal()}}>ОБРАТНЫЙ ЗВОНОК</Button>
          </div>
        </section>

        <section className="partners-landing">
          <div className="container">
            <h2 className="landing-title">Контактная информация</h2>

{/*            <div className="partners-landing-items">
              <h4 className="partners-landing-items-title">Управление туризма и внешних связей Восточно-Казахстанской области</h4>
              <div className="partners-landing-item">
                <div className="partners-landing-item-preview gerb">
                </div>
                <div className="partners-landing-item-content">
                  <h5 className="partners-landing-item-title">Оказываемая поддержка</h5>
                  <p className="partners-landing-item-text">
                    Поддержка и сопровождение инвестиционных проектов
                    Помощь в поиске земельного участка и оформлении необходимых документов.<br/>
                    <b>Телефон:</b> <a href="tel:+77232713213" target="_blank" rel="noopener noreferrer">+7 (7232) 71-32-13</a>. <b>E-mail:</b> <a href="mailto:vko.turizmkense@akimvko.gov.kz" target="_blank" rel="noopener noreferrer">vko.turizmkense@akimvko.gov.kz</a>
                  </p>
                </div>
              </div>
            </div>*/}

            <div className="partners-landing-items">
              <h4 className="partners-landing-items-title">Дополнительные ресурсы</h4>

              <div className="partners-landing-item">
                <div className="partners-landing-item-preview kazakh-tourism">
                </div>
                <div className="partners-landing-item-content">
                  <h5 className="partners-landing-item-title">Оказываемая поддержка</h5>
                  <p className="partners-landing-item-text">
                    Информационно-аналитическая поддержка проектов в туристической отрасли Казахстана.<br/>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.qaztourism.kz"><b>www.qaztourism.kz</b></a>
                  </p>
                </div>
              </div>

              <div className="partners-landing-item">
                <div className="partners-landing-item-preview damu-kz">
                </div>
                <div className="partners-landing-item-content">
                  <h5 className="partners-landing-item-title">Оказываемая поддержка</h5>
                  <p className="partners-landing-item-text">
                    Информация о мерах государственной поддержки для субъектов малого и среднего бизнеса.<br/>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.damu.kz"><b>www.damu.kz</b></a>
                  </p>
                </div>
              </div>

              <div className="partners-landing-item">
                <div className="partners-landing-item-preview kzppp-kz">
                </div>
                <div className="partners-landing-item-content">
                  <h5 className="partners-landing-item-title">Оказываемая поддержка</h5>
                  <p className="partners-landing-item-text">
                    Информация о заключении государственно-частного партнерства, а также аналитическая и экспертная поддержка.<br/>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.kzppp.kz"><b>www.kzppp.kz</b></a>
                  </p>
                </div>
              </div>

              <div className="partners-landing-item">
                <div className="partners-landing-item-preview kazakh-invest">
                </div>
                <div className="partners-landing-item-content">
                  <h5 className="partners-landing-item-title">Оказываемая поддержка</h5>
                  <p className="partners-landing-item-text">
                    «Одно окно» для инвестора. Поддержка потенциальных иностранных инвесторов по всем вопросам, включая содействие в получении финансирования, разрешений и иных государственных услуг<br/>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.invest.gov.kz"><b>www.invest.gov.kz</b></a>
                  </p>
                </div>
              </div>

              <div className="partners-landing-item">
                <div className="partners-landing-item-preview kdb-kz">
                </div>
                <div className="partners-landing-item-content">
                  <h5 className="partners-landing-item-title">Оказываемая поддержка</h5>
                  <p className="partners-landing-item-text">
                    Средне- и долгосрочное финансирование инвестиционных проектов в несырьевом секторе экономики.<br/>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.kdb.kz"><b>www.kdb.kz</b></a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    );
  }
}

export default withRouter(HomePanel);
