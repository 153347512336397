import React, { Component } from "react";
import { Spin, Empty } from "antd";

// Styles
import './choosingCategoryBlock.scss'
import { BackendUrl, getInvestProjectTypes } from "../../../../actions/mainActions";

class ChoosingCategoryBlock extends Component {
  constructor(props) {
    super(props)
    this.state = {
      typesDataList: [],
      isLoading: true
    }
  }

  componentDidMount() {
    getInvestProjectTypes(this.props.selectedCategoryId).then(response => {
      this.setState({
        typesDataList: response.data.data,
        isLoading: false
      })
    })
  }

  categories = [
    {
      img: require('../../../../assets/images/tmp/project-categories/1.jpg'),
      title: 'ГЛЭМПИНГ'
    },
    {
      img: require('../../../../assets/images/tmp/project-categories/2.jpg'),
      title: 'Гостиница'
    },
    {
      img: require('../../../../assets/images/tmp/project-categories/3.jpg'),
      title: 'Зона отдыха'
    },
    {
      img: require('../../../../assets/images/tmp/project-categories/4.jpg'),
      title: 'Санаторий'
    },
    {
      img: require('../../../../assets/images/tmp/project-categories/5.jpg'),
      title: 'Визит центр'
    },
    {
      img: require('../../../../assets/images/tmp/project-categories/6.jpg'),
      title: 'ОПС'
    }
  ]

  render() {
    return (
      <>
        <h3 className="step-title">Выберите категорию проекта</h3>
        <div className="step-cards">
          {
            !this.state.isLoading
            ? <div className="types-list categories-list">
              {
                this.categories.map(type => (
                  <button key={type.title} onClick={() => this.props.nextTab()} className="image-card">
                    <span>{type.title}</span>
                    <img src={`${type.img}`} alt={type.title}/>
                  </button>
                ))
              }
            </div>
            : <Spin/>
          }
        </div>
      </>
    )
  }
}

export default ChoosingCategoryBlock;
