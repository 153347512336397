import React, { Component } from "react";
import { Button, Checkbox, Table } from "antd";
import { BackendUrl } from "../../../../actions/mainActions";

// Icons
import {ReactComponent as StarGrey} from "../../../../assets/images/icons/star-grey.svg";
import {ReactComponent as StarGreen} from "../../../../assets/images/icons/star-green.svg";

// Styles
import './totalApplication.scss'

class NewProjectCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableValues: [
        {
          id: 1,
          name: 'Интернет-магазине "KazAl"',
          raiting: 3,
          portfolio: 'http://kazal.kz',
          production: '7 дней',
          delivery: '1 неделя',
          price: 4400000,
          price_without_installation: 3500000,
          selectWorkPrice: 4400000,
          withMontage: true
        },
        {
          id: 2,
          name: 'Glamping kaz',
          raiting: 3,
          portfolio: 'http://surl.li/elfhp',
          production: '10 дней',
          delivery: '3 неделя',
          price: 4750000,
          price_without_installation: 3850000,
          withMontage: true
        },
      ]
    }
  }

  componentDidMount() {
    this.props.selectCompany(this.props.basketData.data, this.state.tableValues[0])
  }

  render() {
    const formatToCurrnecy = number => new Intl.NumberFormat('ru-RU').format(number) + ' тг'

    const onChangeWorkType = (data, price, type) => {
      let newList = Array.from(new Set(this.state.tableValues))
      
      newList.forEach(company => {
        company.selectWorkPrice = company.id === data.id ? price : company.selectWorkPrice
        company.withMontage = company.id === data.id ? type : company.withMontage
      })
      this.setState({
        tableValues: newList
      })

      if (data.id === this.props.basketData.company?.id) this.props.selectCompany(this.props.basketData.data, data)
    }

    const tableColumns = [
      {
        title: "Компания",
        dataIndex: "name",
        key: "name",
        render: (name, raiting) => (
          <div className="title-block">
            <h4 className="title">{name}</h4>
            <div className="stars">
              <StarGreen />
              <StarGreen />
              <StarGreen />
              <StarGrey />
              <StarGrey />
            </div>
          </div>
        ),
      },
      {
        title: "Портфолио",
        dataIndex: "portfolio",
        key: "portfolio",
        render: portfolio => (
          <a
            href={portfolio}
            target="_blank"
            rel="noopener noreferrer"
          >
            {portfolio}
          </a>
        ),
      },
      {
        title: "Изготовление",
        dataIndex: "production",
        key: "production"
      },
      {
        title: "Доставка",
        dataIndex: "delivery",
        key: "delivery"
      },
      {
        title: "Стоимость с монтажом",
        dataIndex: "price",
        key: "price",
        render: (price, data) => (
          <Checkbox
            className="checkbox-variant"
            style={{marginBottom: '32px', marginLeft: '0px'}}
            checked={data.withMontage === true}
            disabled={data.id !== this.props.basketData.company?.id }
            onChange={(e) => {
              if (e.target.checked) onChangeWorkType(data, price, true)
            }}
          >
            <div className="label">{formatToCurrnecy(price)}</div>
          </Checkbox>
        )
      },
      {
        title: "Цена без монтажа",
        dataIndex: "price_without_installation",
        key: "price_without_installation",
        render: (price_without_installation, data) => (
          <Checkbox
            className="checkbox-variant"
            style={{marginBottom: '32px', marginLeft: '0px'}}
            checked={data.withMontage === false}
            disabled={data.id !== this.props.basketData.company?.id }
            onChange={(e) => {
              if (e.target.checked) onChangeWorkType(data, price_without_installation, false)
            }}
          >
            <div className="label">{formatToCurrnecy(price_without_installation)}</div>
          </Checkbox>
        )
      },
      {
        title: "",
        dataIndex: "id",
        key: "id",
        width: 100,
        render: (id, data) => (
          <Button
            type="primary"
            className={data.id === this.props.basketData.company?.id ? 'active': ''}
            onClick={() => this.props.selectCompany(this.props.basketData.data, data)}
          >
            {data.id === this.props.basketData.company?.id ? 'Выбрано' : 'Выбрать'}
          </Button>
        ),
      },
    ];

    return (
      <div className="construction-item">
        <div className="construction-basicInfo">
          <div className="left">
            <div className="preview" style={{backgroundImage: `url(${`${BackendUrl}${this.props.basketData.data.image_path}`})`}}></div>
            <div className="center">
              <h3 className="title">{this.props.basketData.data.name}</h3>

              <div className="construction-basicInfo-values">
                <div>
                  <p className="value">Вместимость: <span>{this.props.basketData.data.beds}</span></p>
                  <p className="value">Температура:  <span>-{this.props.basketData.data.temperature}</span></p>
                </div>

                <div>
                  <p className="value">Площадь пола: <span>{this.props.basketData.data.size} м<sup>2</sup></span></p>
                  <p className="value">Cанузел/ванна:  <span>{this.props.basketData.data.title}</span></p>
                </div>
              </div>
            </div>
          </div>
          <div className="construction-count-and-price">
            <h3 className="price">{formatToCurrnecy((this.props.basketData.company ? this.props.basketData.company?.selectWorkPrice : 0) * this.props.basketData.count)}</h3>
            
            <div className="count-control">
              <button onClick={() => this.props.minusKind(this.props.basketData.data, this.props.basketData.count)}><span>-</span></button>
              <div className="count"><span>{this.props.basketData.count}</span></div>
              <button onClick={() => this.props.plusKind(this.props.basketData.data, this.props.basketData.count)}><span>+</span></button>
            </div>
          </div>
        </div>

        <p className="construction-description">
          В базовую комплектацию входят: металлический каркас окрашенный порошковой краской, белый тент, 1 вход. Расположение прозрачных окон обсуждается индивидуально в зависимости от пожеланий, назначения и условий места установки. 
        </p>

        <div className="companies">
          <h4 className="companies-title">Выбрать производителя/поставщика:</h4>

          <Table
            columns={tableColumns}
            dataSource={this.state.tableValues}
            className="companies-table"
            bordered
            pagination={false}
          />
        </div>
      </div>
    )
  }
}

export default NewProjectCard;
